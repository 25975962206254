import React from "react";
import FormHome from "../Home/FormHome";

const TransparentHeader = ({ headertitle, bgimg, Subheader }) => {
  return (
    <div
      className="breadcrumb-area"
      style={{ backgroundImage: `url("${bgimg}")` }}
    >
      <div className="flex flex-col lg:flex-row justify-center items-center gap-5 w-full h-full">
      <div className="text-center flex flex-col justify-end pb-[5%] relative">
        <h1 className="text-white">{headertitle}</h1>
        {
          headertitle === Subheader ?
            <h6 className="text-white">Home - {Subheader}</h6>
            :
            <h6 className="text-white">{Subheader}</h6>
        }
      </div>
      {
        headertitle === 'Contact' ?
        null
        :
        <div className="relative">
        <FormHome/>
      </div>
      }

      </div>

    </div>
  );
};

export default TransparentHeader;
