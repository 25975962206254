import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import FormHome from "./FormHome";
function HeroVideo({urlVideo}) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center items-center">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full h-full object-cover after:bg-[#333]"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute md:text-start text-center text-white">
                    <div className="mt-10 w-full text-center p-4 flex flex-col lg:flex-row items-center gap-3">
                        <div className="lg:w-1/2">
                        <h1 className="text-[45px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                        <p className="px-5 md:px-[20%]">{rpdata?.dbSlogan?.[9].slogan}</p>
                        <ButtonContent btnStyle="three" />
                        </div>

                        <div className="lg:w-1/2">
                        <FormHome/>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;